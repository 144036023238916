import React, { useState } from 'react';
import img1 from '../images/gallery/1.jpeg';
import img2 from '../images/gallery/2.jpeg';
import img3 from '../images/gallery/3.jpeg';
import img4 from '../images/gallery/4.jpeg';
import img5 from '../images/gallery/5.jpeg';
import img6 from '../images/gallery/6.jpeg';
import img7 from '../images/gallery/7.jpeg';
import img8 from '../images/gallery/8.jpeg';
import img9 from '../images/gallery/9.jpeg';
import img10 from '../images/gallery/10.jpeg';
import img11 from '../images/gallery/11.jpeg';
import img12 from '../images/gallery/12.jpeg';
import img13 from '../images/gallery/13.jpeg';
import img14 from '../images/gallery/14.jpeg';
import img15 from '../images/gallery/15.jpeg';
import img16 from '../images/gallery/16.jpeg';
import img17 from '../images/gallery/17.jpeg';
import img18 from '../images/gallery/18.jpeg';
import img19 from '../images/gallery/19.jpeg';
import img20 from '../images/gallery/20.jpeg';
import img21 from '../images/gallery/21.jpeg';
import img22 from '../images/gallery/22.jpeg';
import img23 from '../images/gallery/23.jpeg';
import Navibar from './navibar';

const Gallery = () => {
    const images = [
        img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23
    ];

    const [activeImage, setActiveImage] = useState(null);

    const handleClick = (img) => {
        setActiveImage(img);
    };

    const handleClose = () => {
        setActiveImage(null);
    };

    return (
        <div>
            <center>
                <h1 className='gallery_page-h1-01'><span>Image</span> Gallery</h1>
                <div className='gallery_grid'>
                    {images.map((img, index) => (
                        <img
                            key={index}
                            src={img}
                            alt={`Gallery Image ${index + 1}`}
                            className='gallery_image'
                            onClick={() => handleClick(img)}
                        />
                    ))}
                </div>
                
                {activeImage && (
                    <div className='overlay' onClick={handleClose}>
                        <img src={activeImage} alt="Enlarged" className='enlarged_image' />
                    </div>
                )}
            </center>
            <Navibar />
        </div>
    );
};

export default Gallery;
