import React from 'react'

import img1 from '../images/gallery/1.jpeg';
import img2 from '../images/gallery/2.jpeg';
import img3 from '../images/gallery/3.jpeg';
import img4 from '../images/gallery/4.jpeg';
import img5 from '../images/gallery/5.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import Navibar from './navibar';


const Events = () => {


  const images = [img1, img2, img3, img4, img5]
  

  const Date_data = [
    "",
    "",
    "",
    "",
    ""
  ]

  const Date_day = [
    "2024",
    "2024",
    "2024",
    "2024",
    "2024"
  ]


  return (
    <div>
      <center>
        <h1 className='events-h1-01'>Our Latest <span>Evevnts</span></h1>
        <div className='events_main-cnt-01'>

          {Date_data.map((date, i) =>{
            return(
              <div key={i} className='events_main-cnt-01-sub-01'>
                <div className='events_main-cnt-01-sub-01-sub-01'>
                  <span className='home-wlap_cnt-03-sub-01-sub-cnts-01P-date-top-01-span-01'>{Date_day[i]}</span><br/>
                  <span className='home-wlap_cnt-03-sub-01-sub-cnts-01P-date-top-01-span-01'>{date}</span>
                </div>
                <div className='events_main-cnt-01-sub-01-sub-02'>
                  <img src={images[i]} alt='image' />
                </div>
                <br/>
                <div className='home-wlap_cnt-03-sub-01-sub-cnts-01P-date-top-03'>
                    <span className='home-wlap_cnt-03-sub-01-sub-cnts-01P-date-top-03-span-01'> <strong><FontAwesomeIcon icon={faCalendarDays} /></strong> 2024</span><br/>
                    <span className='home-wlap_cnt-03-sub-01-sub-cnts-01P-date-top-03-span-02' > <strong><FontAwesomeIcon icon={faLocationDot} /></strong> Bengaluru</span>
                </div>
              </div>
            )
          })}
          

        </div>
      </center>
      <Navibar />
    </div>
  )
}

export default Events
