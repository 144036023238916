import { faBars, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

const Navibar = () => {

    const [show, setShow] = useState(false);

  return (
    <div onClick={()=>{show && setShow(false)}} >
        <center>
            {show ?
                <div>
                    <div className='navi_bar_page-main-cnt-02'>

                        <div onClick={() =>{setShow(false)}} className='navi_bar_page-main-cnt-02-sub-01'>
                            <FontAwesomeIcon icon={faX} />
                        </div>

                        <div onClick={()=>{window.location.href='/'}} className='buton_navibar-01'>
                            <span>Home</span>
                        </div>

                        <div onClick={()=>{window.location.href='/events'}} className='buton_navibar-01'>
                            <span>Events</span>
                        </div>

                        <div onClick={()=>{window.location.href='/gallery'}} className='buton_navibar-01'>
                            <span>Gallery</span>
                        </div>

                        <div onClick={()=>{window.location.href='/about'}} className='buton_navibar-01'>
                            <span>About</span>
                        </div>

                        {/* <div className='navi_bar_page-main-cnt-02-sub-02'>
                            <div className='navi_bar_page-main-cnt-02-sub-02-sub-01'>

                            </div>

                        </div> */}

                    </div>
                </div>

                :

                <div>
                    <div onClick={()=>{setShow(true)}} className='navi_bar_page-main-cnt-01'>
                        <FontAwesomeIcon className='navi_bar_page-main-cnt-01-icon-01' icon={faBars} />
                    </div>
                </div>

            }
            

            <div>

            </div>
        </center>
    </div>
  )
}

export default Navibar
