import React from 'react'
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import HomePage from './pages/home';
import "./App.css"
import Gallery from './pages/gallery';
import About from './pages/about';
import Events from './pages/events';

const App = () => {
  return (
    <div>
      <center>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/about' element={<About />} />
            <Route path='/events' element={<Events />} />
          </Routes>
        </BrowserRouter>
      </center>
    </div>
  )
}

export default App
