import React from 'react';
import img1 from "../images/gallery/1.jpeg"
import img2 from "../images/about1.jpg"
import Navibar from './navibar';


const About = () => {


  return (
    <div className='about-page'>
      <div className='about-container'>
        <h1 className='about-container-h1-01'>About <span>Us</span></h1>
        
        <div className='about-content'>
          <div className='about-text'>
            <h2 className='about-text-h2-01-01'>Our <span>Story</span></h2>
            <p>Guru Kalamandalam Vineetha Sreejin is the <strong>founder</strong> and <strong>Director</strong> of Kalanirdhari Performing Arts Centre, holding an M.A. in Bharathanatyam from Bharathidasan University and a B.A. in Mohiniyattam from Kerala Kalamandalam. Mrs. Vineetha Sreejin is a proficient classical dancer with over 11 years of experience in Bharatanatyam, Mohiniyattam, and Kuchipudi. She has performed at various individual concerts in India and abroad</p>
          </div>
          <div className='about-image'>
            <img src={img1} alt='About Us' />
          </div>
        </div>
        


        {/* <div className='kalanirdhari-section'>
          <h2>About Kalanirdhari</h2>
          <p>
            Kalanirdhari Dance Centre has been functional for the last 6 years, teaching Bharatanatyam, Mohiniyattam, and Kuchipudi. Guru Kalamandalam Vineetha ensures that students learn classical dances in their purest form, embracing the gurukul style and emotionally connecting with the story and audience.
          </p>
          <ul>
            <li>We provide dance certification courses in association with Bharathidasan University.</li>
            <li>Recently, our students showcased their talent at Jagan Mohana Palace, receiving wide coverage in newspapers and news channels, with participation awards.</li>
            <li>Yearly, 3-4 performances are covered by eligible students as part of their dance curriculum.</li>
            <li>Our students have participated in dance performances as part of a World Record, earning 10 world record titles and mementos.</li>
          </ul>
          <h3>Costumes, Accessories, Make-Up, and Fancy Items Available Here...</h3>
          <h3>Special Offers:</h3>
          <p>No Registration Fee On Opening Day</p>
          <p>Contact: 97466 37350 / 88480 55518</p>
          <p>Location: NR Mansion, V.S. Reddy Colony, Kadugodi, Bangalore 560 067</p>
        </div> */
        }



      </div>

      <div className='about-content-01'>
        <h2>Awards and Recognitions</h2>
      </div>
      <div style={{height : "30px"}}>

      </div>

      <div className='about-content-02'>
        <ul>
          <li className='about_page-points-01'>Kalathilaka Pattam in 2014 by Akhila Kerala Ezhuthacha Samajam</li>
          <li className='about_page-points-02'>Natysiharam Award from Thiranviruth in Thanjavur 2020</li>
          <li className='about_page-points-01'>Participated and coordinated ICMDA 10 World Record Mega Event at Bangalore 2020</li>
          <li className='about_page-points-02'>Nirtha Vibhushan Award for Bharatanatyam from the 6th All India Dance Festival conducted in Goa 2022</li>
          <li className='about_page-points-01'>Nritha Vilasini Award at Udagamandalam Dance Festival, organized by Nrithyadhi Kalakshetra and Thodar Community Development Association - Feb. 2023</li>
          <li className='about_page-points-02'>Ambhavilas Award for contribution to classical dance from Kalpasree Performing Arts Centre at Jagan Mohana Palace, Mysore</li>
        </ul>

      </div>

      <div style={{height: "100px"}}>

      </div>
      <Navibar />

    </div>
  );
};

export default About;
