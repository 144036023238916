import React, { useEffect, useState } from 'react'
import img1 from "../images/img1.jpg"
import img2 from "../images/img2.jpg"
import img3 from "../images/img3.jpg"
import img4 from "../images/img4.jpg"
import img5 from "../images/img5.jpg"
// import v1 from "../videos/v1.mp4";
import n1 from "../images/gallery/n1.jpg"
import n2 from "../images/gallery/n2.jpeg"
import n3 from "../images/gallery/n3.jpeg"
import img7 from "../images/img6.jpg";
import img20 from "../images/gallery/20.jpeg";
import img21 from "../images/gallery/24.jpeg";
import img22 from "../images/gallery/25.jpeg";
import insta from "../images/insta.png";
import fb from "../images/fb.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faContactBook, faLocationDot, faPerson, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import Navibar from './navibar'

const HomePage = () => {

  const Options = ["Home", "About", "Events", "Gallery"]
  const Wall1 = [img1, img2]
  const Images = [n1, n2, n3]
  const Imageee = [img20, img21, img22]
  const sm_img = [insta, fb]
  const sm_links = ["https://www.instagram.com/vineetha_kalanirdhari", "https://www.facebook.com/people/Kalanirdhari-performing-arts-and-research-centreBangalore/100063743122148/?rdid=DJy0TV0xFbk9hll7&share_url=https%253A%252F%252Fwww.facebook.com%252Fshare%252FdCmjkYKyu1vJFj1X%252F&checkpoint_src=any"]

  
  const Text1 = [
    "Let's Keep on Dancing",
    "Learn to Grow as a Dancer & as a Person",
    "Dancing"
  ]
  const CntData = [
    "Part of a World Record",
    "Performances",
    "Certification Courses"
  ]

  const evntsData = [
    "Jalahalli  Sree  ayappa temple auditorium",
    "Swamy saranam temple trust",
    "Nritha vilasini awards at udagamandalam festival"
  ]

  const eventsDate = [
    "2024",
    "2024",
    "2024"
  ]

  const data_sub_data = [
    "",
    "",
    ""
  ]

  const eventMonth = [
    "Sep",
    "Aug",
    "Jun"
  ]

  const EventDate_Time = [
    "September 09 2024",
    "August 08 2024 ",
    "June 05 2024 "
  ]

  const Events_Location = [
    "Bengaluru",
    "Bengaluru",
    "Bengaluru"
  ]

  const Events_data_Subline = [
    "",
    "",
    "",
  ]



  useEffect(() => {
    const blocks = document.querySelectorAll('.home-wlap_cnt-02-map-cnt-01');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
          entry.target.classList.remove('hide');
        } else {
          entry.target.classList.remove('show');
          entry.target.classList.add('hide');
        }
      });
    }, { threshold: 0.3 });

    blocks.forEach((block) => {
      observer.observe(block);
    });

    // Cleanup observer on unmount
    return () => observer.disconnect();
  }, []);


  useEffect(() => {
    const blocks = document.querySelectorAll('.home-wlap_cnt-03-sub-01-sub-cnts-01P');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
          entry.target.classList.remove('hide');
        } else {
          entry.target.classList.remove('show');
          entry.target.classList.add('hide');
        }
      });
    }, { threshold: 0.4 });

    blocks.forEach((block) => {
      observer.observe(block);
    });

    // Cleanup observer on unmount
    return () => observer.disconnect();
  }, []);


  useEffect(() => {
    const blocks = document.querySelectorAll('.home-wlap_cnt-05-sub-cnt-01');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
          entry.target.classList.remove('hide');
        } else {
          entry.target.classList.remove('show');
          entry.target.classList.add('hide');
        }
      });
    }, { threshold: 0.2});

    blocks.forEach((block) => {
      observer.observe(block);
    });

    // Cleanup observer on unmount
    return () => observer.disconnect();
  }, []);


  useEffect(() => {
    const blocks = document.querySelectorAll('.home-wlap_cnt-05');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
          entry.target.classList.remove('hide');
        } else {
          entry.target.classList.remove('show');
          entry.target.classList.add('hide');
        }
      });
    }, { threshold: 0.2});

    blocks.forEach((block) => {
      observer.observe(block);
    });

    // Cleanup observer on unmount
    return () => observer.disconnect();
  }, []);

  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [currentTextIndex, setCurrentTextIndex] = useState(0)
  const [animateText, setAnimateText] = useState(false)

  useEffect(() => {
    // Set intervals to change the image and text every 5 seconds
    const imageInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % Wall1.length)
    }, 5000)

    const textInterval = setInterval(() => {
      setAnimateText(false) // Reset animation
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % Text1.length)
        setAnimateText(true) // Trigger animation
      }, 100) // Delay slightly to ensure animation restarts
    }, 5000)

    return () => {
      clearInterval(imageInterval)
      clearInterval(textInterval)
    }
  }, [Wall1.length, Text1.length])

  // Conditional style logic for <h2>
  const getTextStyle = (index) => {
    if (index === 0) {
      return { color: "#ffffff", fontSize: "30px", fontFamily: "Gowun Batang" }
    } else if (index === 1) {
      return { color: "#000000", fontSize: "30px", fontFamily: "Playpen Sans" }
    } else {
      return { color: "#000000", fontSize: "30px", fontFamily: "Fascinate Inline" }
    }
  }


  return (
    <div>
      <center style={{marginTop : "10px"}}>
        <h1 className='home-logo_name'>kalanirdhari</h1>
        <div className='home-menue_cnt_01'>
          {Options.map((data, i) => (
            <div key={i} className='home-menue_cnt_01_sub-cnt-01'>
              <span onClick={()=>{window.location.href=`/${data.toLocaleLowerCase()}`}} className='menu-item'>{data}</span>
            </div>
          ))}
        </div>

        {/* Display the current text and image based on the state */}
        <div className='home-wlap_cnt-01'>
          <div className="home-wlap_cnt-01-sub-01">
            <h2 
              style={getTextStyle(currentTextIndex)}
              className={animateText ? 'slide-in' : ''}>
              {Text1[currentTextIndex]}
            </h2> 
          </div>
          <img src={Wall1[currentImageIndex]} alt='sliding images' />
        </div>
        <br/>

        <div className='home-wlap_cnt-02'>
          {CntData.map((data, i) => (
            <div key={i} className='home-wlap_cnt-02-map-cnt-01'>
              <div className='home-wlap_cnt-02-map-cnt-01-sub-01'>
                {/* Show only one image per section, using the index `i` */}
                <img src={Images[i]} alt={`image-${i}`} />
              </div>
              <span className='home-wlap_cnt-02-map-cnt-01-sub-01-span-01'>{data}</span><br/>
              <span className='home-wlap_cnt-02-map-cnt-01-sub-01-span-02'>{data_sub_data[i]}</span>
            </div>
          ))}
        </div>
        <br/>

        <div style={{height : "20px"}}>

        </div>

        <div className='home-wlap_cnt-03'>
          <h2 className='home-wlap_cnt-03-h2-01'> keep track of our <span>upcoming events</span></h2>

          <div className='home-wlap_cnt-03-sub-01'>

            {evntsData.map((data, i) =>{
              return(
                <div key={i} className='home-wlap_cnt-03-sub-01-sub-cnts-01P'>
                  <div className='home-wlap_cnt-03-sub-01-sub-cnts-01P-date-top-01'>
                    <span className='home-wlap_cnt-03-sub-01-sub-cnts-01P-date-top-01-span-01' >{eventsDate[i]}</span><br/>
                    <span className='home-wlap_cnt-03-sub-01-sub-cnts-01P-date-top-01-span-02' >{eventMonth[i]}</span>
                  </div>

                  <div className='home-wlap_cnt-03-sub-01-sub-cnts-01P-date-top-02'>
                    <img src={Imageee[i]} alt='imgs' />
                  </div>
                  
                  <br/>

                  <div className='home-wlap_cnt-03-sub-01-sub-cnts-01P-date-top-03'>
                    <span className='home-wlap_cnt-03-sub-01-sub-cnts-01P-date-top-03-span-01'> <strong><FontAwesomeIcon icon={faCalendarDays} /></strong> {EventDate_Time[i]}</span><br/>
                    <span className='home-wlap_cnt-03-sub-01-sub-cnts-01P-date-top-03-span-02' > <strong><FontAwesomeIcon icon={faLocationDot} /></strong> {Events_Location[i]}</span>
                  </div>

                  <div className='home-wlap_cnt-03-sub-01-sub-cnts-01P-date-top-04'>
                    <h3>{data}</h3>
                  </div>
                  <div className='home-wlap_cnt-03-sub-01-sub-cnts-01P-date-top-05'>
                    {Events_data_Subline[i]}
                  </div>

                </div>
              )
            })}

          </div>
        </div>

        {/* <div className='home-wlap_cnt-04'>
        <video width="600" controls>
          <source src={v1} type="video/mp4" />
        </video>
        </div> */}

        <h2 className='home_page_h2-may_be_01'> Meet Our <span>amazing Dance</span> Teachers	</h2>

        <p className='home_page-para-maybe-01'>Discover the talent and passion behind our dance classes, led by inspiring professionals.</p>



        <div className='home-wlap_cnt-05'>
          <img src={img7} alt='image' />
          <div className='home-wlap_cnt-05-sub-cnt-01'>
            {/* <div className='home-wlap_cnt-05-sub-cnt-01-sub-cnt-01'>
              <img src={img8} alt='image' />
            </div> */}
            <span className='home-wlap_cnt-05-sub-cnt-01-span-01'>Vineetha Sreejin</span><br/>
            <div style={{height : "10px"}}></div>
            <div className='home-wlap_cnt-05-sub-cnt-01-sub-div-01' onClick={()=>{window.location.href = "tel:+919606892520";}}>
              <span className='home-wlap_cnt-05-sub-cnt-01-span-02'><strong><FontAwesomeIcon icon={faPhone} /> </strong> +91 9746637350</span>
            </div>

            <div className='home-wlap_cnt-05-sub-cnt-01-sub-div-02'>
              {sm_img.map((icons, ic)=>{
                return(
                  <div className='home-wlap_cnt-05-sub-cnt-01-sub-div-02-icon-01' onClick={()=>{window.location.href=`${sm_links[ic]}`}}>
                    <img src={icons} alt="icons" />
                  </div>
                )
              })}
              
            </div>

            <br/>

            <div onClick={()=>{window.location.href = '/about'}} className='home-wlap_cnt-05-sub-cnt-01-sub-div-03'>
              <span>More</span>
            </div>

          </div>
        </div>


        <br/>

        {/* Footer */}
        <div className="home-wlap_cnt-06">
          <div className='home-wlap_cnt-06-sub-01'>
            <h3 className='home-logo_name' style={{marginLeft : "20px"}}>kalanirdhari</h3>
          </div>
          
          <div className='home-wlap_cnt-06-sub-02'>
            

            <div className='home-wlap_cnt-06-sub-02-sub-01'>
              <strong className='home-wlap_cnt-06-sub-02-sub-01-strong-01'>Pages</strong><br/>
              
              <div>
                <span onClick={()=>{window.location.href='/events'}}>Events</span>
              </div>
              
              <div onClick={()=>{window.location.href='/gallery'}}>
                <span>Gallery</span>
              </div>
              
              <div onClick={()=>{window.location.href='/about'}}>
                <span>About</span>
              </div>
            </div>

            <div className='home-wlap_cnt-06-sub-02-sub-01'>
              <strong className='home-wlap_cnt-06-sub-02-sub-01-strong-01'>Contact us</strong><br/>
              
              <div className='home-wlap_cnt-06-sub-02-sub-01-sub-01'>
                <p className='home-wlap_cnt-06-sub-02-sub-01-paragaraph-01'><FontAwesomeIcon icon={faLocationDot} className='home-wlap_cnt-06-sub-02-sub-01-sub-01-icon-01' onClick={()=>{window.location.href='https://maps.app.goo.gl/RnZXXRwm3BTTa1KV7'}} /> <span>AB Vajapayee Rd, VSR Layout, V S Reddy Colony, Kadugodi, Bengaluru, Karnataka 560067</span></p>
              </div>

              <div className='home-wlap_cnt-06-sub-02-sub-01-sub-01'>
                <p className='home-wlap_cnt-06-sub-02-sub-01-paragaraph-01'><FontAwesomeIcon icon={faPhone} className='home-wlap_cnt-06-sub-02-sub-01-sub-01-icon-01' onClick={()=>{window.location.href='https://maps.app.goo.gl/RnZXXRwm3BTTa1KV7'}} /> <span> : 9746637350, 8848055518</span></p>
              </div>

              

              <div className='home-wlap_cnt-06-sub-02-sub-01-sub-02'>
                <div className='home-wlap_cnt-06-sub-02-sub-01-sub-01-01'>
                  <img src={insta} alt='image1' />

                </div>

                <div className='home-wlap_cnt-06-sub-02-sub-01-sub-01-01'>
                  <img src={fb} alt='image1' />
                </div>
              </div>

            </div>

            
            

          </div>
          <br/>
          <br/>

          <div className='home-wlap_cnt-06-sub-03'>
            <span>Copyright © 2023 KALANIRDHARI. All rights reserved.</span>
          </div>

        </div>
        <br/>


      </center>
      <Navibar />
    </div>
  )
}

export default HomePage
